import NextErrorComponent from "next/error";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { ErrorType } from "../model";
import Image from "next/image";
import * as Sentry from "@sentry/nextjs";

function ErrorMessage({
  statusText,
  message,
  showMessage,
  showStatusText
}: any) {
  return (
    <div className="error-message-wrapper">
      {showStatusText && <h3>{statusText}</h3>}
      {message && showMessage && <p>{message}</p>}
    </div>
  );
}

export function CustomError({
  showRedirectText,
  heading,
  statusText,
  message,
  redirectText,
  isFullWidth,
  err,
  showMessage = true,
  showStatusText,
  statusCode,
  isModal,
  redirectLink,
  children,
  ...props
}: ErrorType) {
  const [_message] = useState(
    message ||
      "We're experiencing technical difficulties. Please try again later."
  );
  const [_heading] = useState(heading || "Oops! Something went wrong.");
  const [_statusText] = useState(statusText || "500");
  const [_redirectText] = useState(redirectText || "Home Page");
  const [_showRedirectText] = useState(
    showRedirectText === false ? false : true
  );

  useEffect(() => {
    Sentry.captureException(err);
  }, [err]);

  return (
    <section className={`custom-error ${isModal ? "modal" : ""}`} {...props}>
      {!isModal && (
        <div className="custom-error__header">
          <Image
            src="/assets/images/logo.svg"
            height={32}
            width={208}
            alt="bloccpay-logo"
          />
        </div>
      )}

      <main style={isFullWidth ? { maxWidth: "100%" } : {}}>
        <div className={`custom-error__inner-container `}>
          <Image
            src={`/assets/images/error-${statusCode}.png`}
            height={200}
            width={250}
            alt={`error-${statusCode}`}
          />
          <h1>{_heading}</h1>
          <div style={isFullWidth ? { width: "100%" } : {}}>
            <ErrorMessage
              showStatusText={showStatusText}
              showMessage={showMessage}
              message={
                _message ||
                "Something went wrong. No worries you didn't break anything. We are working to fix it."
              }
              statusText={_statusText}
            />
          </div>
          {_showRedirectText && (
            <>
              {(redirectLink && (
                <Link href={`${redirectLink || "/"}`} passHref>
                  <span>
                    Go to{" "}
                    <a className="text-primary cursor-pointer">
                      {_redirectText}
                    </a>
                  </span>
                </Link>
              )) || (
                <span>
                  Looks like you&apos;re on the wrong side of town, buddy.{""}
                  Let&apos;s get you back on the{" "}
                  <Link href="/" passHref>
                    <a className="text-primary">right side</a>
                  </Link>
                  .
                </span>
              )}
            </>
          )}
          {children}
        </div>{" "}
      </main>
    </section>
  );
}

(Error as any).getInitialProps = async ({
  res,
  err,
  asPath
}: {
  res: {
    statusCode: number;
  };
  err: {
    statusCode: number;
  };
  asPath: string;
}) => {
  let statusCode = res ? res.statusCode : err.statusCode;
  if (!statusCode) {
    statusCode = 404;
  }
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err
  } as any);
  (errorInitialProps as any).hasGetInitialPropsRun = true;
  if (statusCode < 500) {
    return errorInitialProps;
  }
  if (err) {
    return errorInitialProps;
  }
  return { ...errorInitialProps, statusCode };
};

export default CustomError;
